<template>
  <div>
    <Top></Top>
    <router-view></router-view>
    <el-dialog title="" :visible.sync="dialogFormVisible" :show-close="false">
      <el-form :model="form">
        <el-form-item label="账号" :label-width="formLabelWidth">
          <el-input v-model="form.account"></el-input>
        </el-form-item>
        <el-form-item label="密码" :label-width="formLabelWidth">
          <el-input v-model="form.password" show-password></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="login">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Top from "./Index/components/Top.vue";
import { mapState } from "vuex";
import { localStorageUtil } from "../LocalStorageUtil";
export default {
  components: {
    Top,
  },
  data() {
    return {
      // isDialogVisible:false,
      form: {
        account: "",
        password: "",
      },
      formLabelWidth: "120px",
    };
  },
  computed: {
    ...mapState(["dialogFormVisible"]),
  },
  methods: {
    cancel() {
      this.$store.commit("changeDialog", false);
    },
    //登录方法 再次校验-------------------------------
    async login() {
      let res = await this.$axios("login", {
        account: this.form.account,
        password: this.form.password,
      });
      if (res.message == "登录成功") {
        localStorageUtil.writeExpire(
          "token",
          res.results[0].account,
          7 * 24 * 60 * 60
        );
        this.$store.commit("changeDialog", false);
       
          location.reload(); // 刷新页面
          this.$message("登录成功");
      } else {
        this.$message("登录失败");
      }
    },
  },
};
</script>
<style lang="less" scoped>
/deep/.el-dialog {
  width: 35% !important;
  .el-form-item__label {
    text-align: center;
  }
}
</style>